<template>
<main class="min-h-screen w-full flex">
    <div class="w-full h-screen p-16 bg-gradient-to-tr from-white via-purple">
        <div class="container mx-auto p-4 bg-white">
            <h1 class="text-xlg text-work">
                Forgot Password
            </h1>
            <FormTemplate @response="fromResponse" button="Submit" method="post" action="/auth/forgot" :formdata="user">
                <TextInput type="email" label="Email Address" v-model="user.email" :required="true" ></TextInput>
            </FormTemplate>
        </div>
    </div>
</main>
</template>

<script>

export default {
    name: 'Forgot',
    data: () => ({
        user: {
            email: '',
        },
    }),
    computed: {
    },
    props: [],
    methods: {
        fromResponse(re) {
            if(re && re.data && re.data.status == 'OK') {                
                this.$notify({
                    title: 'Email Sent',
                    text: 'Check your inbox',
                    type: 'Success',
                });
                this.$router.push({path:'/'});
            }else{
                let message = re.data.message ? re.data.message : re;
                this.$notify({
                    title: 'Something went wrong',
                    text: message,
                    type: 'error',
                });
            }
        },
    },
    watch: {

    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
